import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import imagenLogo from '../img/textos/logo-expogame.png';
import editarPerfil from '../img/textos/editar-perfil.png';
import icon01 from '../img/iconos/icon01.svg';
import icon02 from '../img/iconos/icon02.svg';
import icon03 from '../img/iconos/icon03.svg';
import icon04 from '../img/iconos/icon04.svg';
import notificationService from '../services/notifications'

import {
    MDBNavbar,
    MDBContainer, MDBScrollbar,
    MDBBtn,
    MDBSideNav,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBRow,
    MDBCol,
    MDBBadge
} from 'mdb-react-ui-kit';

export default function MenuComponent({ onProfile }) {

    const state = JSON.parse(window.localStorage.getItem('token'));
    const user = JSON.parse(atob(state.user));

    const navigate = useNavigate();
    const [basicOpen, setBasicOpen] = useState(false);
    const [hasNotifications, setHasNotifications] = useState(false);
    const [notifications, setNotifications] = useState(0);

    const handleLoadNotifications = async () => {
        const notificationResult = await notificationService.getAllUserNotificationsByUser(user.id)
        if (notificationResult.length > 0) {
            setNotifications(notificationResult.length)
            setHasNotifications(true)
        } else {
            setNotifications(0)
            setHasNotifications(false)
        }
    }

    useEffect(() => {
        if (basicOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [basicOpen]);

    useEffect(() => {
        handleLoadNotifications();
    }, [])

    return (
        <div className={onProfile ? "banner-gradient" : "banner-admin"}>
            <MDBNavbar className="banner-bg py-lg-4 py-3 d-flex align-items-center">
                <MDBContainer className="p-0">
                    <MDBBtn className="hamburguer-menu" onClick={() => setBasicOpen(!basicOpen)}>
                        <img src={icon03} alt="Nav menu" width={38} className="" />
                    </MDBBtn>
                    <img src={onProfile ? editarPerfil : imagenLogo} alt="Expogame" className="logo-expogame mx-auto pe-auto" width={onProfile ? 260 : 160} onClick={() => { navigate('/home') }} />
                    <div className='position-relative d-inline-block'>
                        <MDBBtn className={`bell-notification ${hasNotifications ? 'has-notifications' : ''}`}
                            onClick={
                                () => {
                                    navigate('/notifications')
                                }
                            }>
                            <img src={icon04} alt="Notificaciones" width={30} className="" />
                        </MDBBtn>
                        {
                            notifications > 0 &&
                            <MDBBadge color='success' light pill className='position-absolute translate middle' style={
                                {
                                    bottom: '0px',
                                    right: '5px',
                                }
                            }>
                                {notifications > 5 ? '5+' : notifications}
                            </MDBBadge>
                        }
                    </div>
                </MDBContainer>
            </MDBNavbar>
            <MDBSideNav isOpen={basicOpen} absolute getOpenState={(e) => setBasicOpen(e)}>
                <MDBBtn id="offcanvas-close" className="ms-auto d-block mt-3" onClick={() => setBasicOpen(!basicOpen)}>
                    <img src={icon02} alt="Cerrar Sidebar" width={38} />
                </MDBBtn>
                <MDBContainer id="offcanvas-header" className="mb-2">
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol className="text-left">
                            <h6 className="fw-bold">{user.userEmail}</h6>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBScrollbar sidenav tag='ul' style={{ maxHeight: 'calc(100% - 260px)' }}>
                    <MDBContainer>
                        {onProfile ?
                            <>
                                <MDBSideNavLink onClick={() => {

                                    navigate('/home')
                                }}>
                                    Inicio
                                </MDBSideNavLink>
                            </>
                            :
                            <>
                                <MDBSideNavLink onClick={() => {

                                    navigate('/profile')
                                }}>
                                    Ver perfil
                                </MDBSideNavLink>
                            </>
                        }
                    </MDBContainer>
                </MDBScrollbar>

                <MDBContainer id="offcanvas-social">
                    <MDBRow>
                        <MDBCol className='d-flex justify-content-start mb-2' id='rrss-text'>
                            <a href="http://www.instagram.com/expogamechile.cl" target="_self" className='px-3 py-2'>
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.twitch.tv/hypeproducciones" target="_self" className='px-3 py-2'>
                                <i className="fab fa-twitch"></i>
                            </a>
                            <a href="https://discord.gg/zYdebkP5AR" target="_self" className='px-3 py-2'>
                                <i className="fab fa-discord"></i>
                            </a>
                            <a href="http://www.facebook.com/expogamechile" target="_self" className='px-3 py-2'>
                                <i className="fab fa-facebook"></i>
                            </a>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <hr></hr>
                <MDBContainer id="offcanvas-footer">
                    <MDBRow>
                        <MDBCol>
                            <MDBSideNavLink onClick={() => {
                                navigate('/login');
                            }}>
                                <img src={icon01} alt="Cerrar Sesión" width={20} className="me-2" /> Cerrar sesión
                            </MDBSideNavLink>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBSideNav>
        </div>
    )
}
