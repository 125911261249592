import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBTabs,
  MDBTabsPane,
  MDBTabsContent,
  MDBCheckbox,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
  MDBInputGroup,
  MDBIcon,
} from "mdb-react-ui-kit";
import imagenHype2 from '../../img/textos/hype_blanco.png';
import imagenLogo from '../../img/textos/logo-expogame.png';
import imagenCube from '../../img/cubo_2023_expogame.png';
import imagenText from '../../img/textos/validador_252x66.png';
import { useNavigate } from "react-router-dom";
import userService from "../../services/users";

const Login = () => {
  const navigate = useNavigate();
  const btnRecoverPassword = () => {
    navigate("/recover-password");
  };
  const btnHomeAdmin = () => {
    navigate("/profile");
  };
  const [basicOpen, setBasicOpen] = useState(false);
  const [adminEmail, setAdminEmail] = useState();
  const [adminPassword, setAdminPassword] = useState();
  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [inputFocussed, setInputFocussed] = useState(false);

  const login = async (e) => {
    e.preventDefault();

    const parameters = {
      userEmail: adminEmail,
      userPassword: adminPassword,
    };

    const result = await userService.adminSignIn(parameters)
    if (result.status === 400) {
      setVaryingMessage(result.messages.error);
      setErrorModal(!errorModal);
    } else {
      let data = atob(result.user)
      data = JSON.parse(data)
      if (parseInt(data.role_id) === 2) {
        localStorage.setItem('token', JSON.stringify(result));
        navigate('/home');
      } else {
        setVaryingMessage('No tienes permisos para acceder a esta sección');
        setErrorModal(!errorModal)
      }
    }

  };

  return (
    <div className="page-welcome d-flex flex-column min-vh-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer >
          <MDBRow >

            <MDBCol className="col-md-6 mx-auto text-center py-4">

              <div className="d-grid gap-2 justify-content-center mb-4">
                <img src={imagenCube} alt="" role="presentation" className="mx-auto" width={136} />
                <img src={imagenLogo} alt="" role="presentation" className="mx-auto" width={160} />
                <img src={imagenText} alt="" role="presentation" className="mx-auto" width={240} />
              </div>
              <form onSubmit={login}>

                <MDBInput
                  className="mb-4"
                  type="email"
                  id="loginName"
                  label="Email"
                  onChange={(e) => setAdminEmail(e.target.value)}
                />

                <MDBInputGroup className='d-block mb-4 input-password'>
                  <MDBInput
                    className='form-control'
                    type={passwordIsVisible ? 'text' : 'password'}
                    id="loginPassword"
                    label="Contraseña"
                    onFocus={() => setInputFocussed(true)}
                    onBlur={() => setInputFocussed(false)}
                    onChange={e => setAdminPassword(e.target.value)}
                  />
                  <MDBBtn
                    className={inputFocussed ? 'icon-button-eye password-selected' : 'icon-button-eye'}
                    onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                    type="button"
                  >
                    <MDBIcon icon={passwordIsVisible ? 'eye-slash' : 'eye'} />
                  </MDBBtn>
                </MDBInputGroup>

                <p><a href="#" onClick={btnRecoverPassword} className="text-center text-white">
                  ¿Olvidaste tu contraseña?
                </a></p>

                <MDBBtn className='button-primary d-block w-100' outline color='dark' type="submit" >
                  Iniciar sesi&oacute;n
                </MDBBtn>
                <a href="https://hypeproducciones.cl" target='_blank' className="d-block text-center mt-4"><img src={imagenHype2} alt="Hype Producciones" width={60} /></a>
              </form>
            </MDBCol>

          </MDBRow>

          <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Error</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className='mb-3'>
                    {errorModal && varyingMessage}
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </MDBContainer>{" "}
      </div>
    </div>
  );
};

export default Login;
