import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from "mdb-react-ui-kit";

import prizeService from "../../services/prizes";
import challengeService from "../../services/challenges";
import imagenCristal from "../../img/cristal-expogame.png";
import cubo from "../../img/cubo_2023_expogame.png";


const ScoreAssignation = () => {
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem("token"));
  const user = JSON.parse(atob(token.user));
  const gamer = JSON.parse(window.localStorage.getItem("gamer"));
  const challenge = JSON.parse(window.localStorage.getItem("challenge"));
  const prize = JSON.parse(window.localStorage.getItem("prize"));

  const [formData, setFormData] = useState({
    gamer_id: gamer.id,
    challenge_id: null,
    prize_id: null,
    user_id: null,
    challengeScoreAmount: null,
  })

  useEffect(() => {
    if (challenge !== null) {
      setFormData({
        gamer_id: gamer.id,
        challenge_id: challenge.id,
        user_id: user.id,
        challengeScoreAmount: challenge.challengeScore
      })
    } else if (prize !== null) {
      setFormData({
        gamer_id: gamer.id,
        user_id: user.id,
        prize_id: prize.id
      })
    }

  }, []);

  const [centredModal, setCentredModal] = useState(false);

  const toggleShow = () => setCentredModal(!centredModal);

  const handleCreateUserScore = async () => {

    if (challenge !== null) {

      const newChallengeUserScore = await challengeService.createChallengeUserScore(formData);
      if (newChallengeUserScore) {
        console.log(newChallengeUserScore);
        localStorage.removeItem("gamer")
        localStorage.removeItem("challenge")
        navigate('/home')
      }
    }
    if (prize !== null) {
      const newPrizeUserScore = await prizeService.createPrizeUserScore(formData);
      if (newPrizeUserScore) {
        localStorage.removeItem("gamer")
        localStorage.removeItem("prize")
        navigate('/home')
      }
    }
  }

  const handleNewScan = () => {
    localStorage.removeItem("challenge")
    localStorage.removeItem("prize")
    //navigate('/qrscanner')
    window.location.href = window.location.origin + "/qrscanner"
  }

  let type = "";
  if (challenge !== null) {
    if (["Descuento Fácil", "Descuento Intermedio", "Descuento Difícil"].includes(challenge.challengeDifficulty)) {
      type = "Descuento"
    } else {
      type = "Recompensa"
    }
  } else if (prize !== null) {
    type = "Venta"
  }
  return (

    <>
      <div className="page-cubes d-flex flex-column text-center min-vh-100">
        <MenuComponent onProfile={false} />
        <MDBContainer className="w-100 text-center">
          <MDBRow className="text-center d-flex align-items-center py-4">
            <MDBCol className="text-center col-md-6 col-12 mb-3 ">
              <h1 className="fw-bold align-text-center">
                {
                  type === "Recompensa" && <>
                    SE ASIGNARÁ
                  </>
                }
                {
                  type === "Descuento" && <>
                    SE DESCONTARÁ
                  </>
                }
                {
                  type === "Venta" && <>
                    SE DESCONTARÁ
                  </>
                }
              </h1>
            </MDBCol>
            <MDBCol lg="6" sm="12">
              {
                type === "Recompensa" && <>
                  <div className="gamerCurrentScore">
                    {challenge.challengeScore}{" "}
                    <img
                      src={imagenCristal}
                      alt="Cristales acumulados"
                      className="icon-cristal"
                      width={60}
                    />
                  </div>
                  <h5 className="points-title">CRISTALES EXPOGAME</h5>
                </>
              }
              {
                type === "Descuento" && <>
                  <div className="gamerCurrentScore">
                    {challenge.challengeScore}{" "}
                    <img
                      src={imagenCristal}
                      alt="Cristales acumulados"
                      className="icon-cristal"
                      width={60}
                    />
                  </div>
                  <h5 className="points-title">CRISTALES EXPOGAME</h5>
                </>
              }
              {
                type === "Venta" && <>
                  <div className="gamerCurrentScore">
                    {prize.prizeScore}{" "}
                    <img
                      src={imagenCristal}
                      alt="Cristales acumulados"
                      className="icon-cristal"
                      width={60}
                    />
                  </div>
                  <h5 className="points-title">CRISTALES EXPOGAME</h5>
                </>
              }
            </MDBCol>
          </MDBRow>
          {
            type === "Recompensa" && <>
              <MDBRow className="text-center d-flex align-items-center py-4">
                <MDBCol className="text-center col-md-6 col-12 mb-3 ">
                  <h1 className="fw-bold align-text-center">
                    Por completar el desafio <br />"{challenge.challengeActivity} / {challenge.challengeName}"
                  </h1>
                </MDBCol>
              </MDBRow>
            </>
          }
          {
            type === "Descuento" && <>
              <MDBRow className="text-center d-flex align-items-center py-4">
                <MDBCol className="text-center col-md-6 col-12 mb-3 ">
                  <h1 className="fw-bold align-text-center">
                    Por operación <br />"{challenge.challengeName}" en <br />"Expogame Center"
                  </h1>
                </MDBCol>
              </MDBRow>
            </>
          }
          {
            type === "Venta" && <>
              <MDBRow className="text-center d-flex align-items-center py-4">
                <MDBCol className="text-center col-md-6 col-12 mb-3 ">
                  <h1 className="fw-bold align-text-center">
                    Por canje de <br />"{prize.prizeName}"
                  </h1>
                </MDBCol>
              </MDBRow>
            </>
          }
          <MDBRow className="text-center d-flex align-items-center py-4">
            <MDBCol className="text-center col-md-6 col-12 mb-3 ">
              <MDBBtn className="button-primary py-0 px-3 " outline color="dark" onClick={toggleShow} >
                Confirmar
              </MDBBtn>
            </MDBCol>
            <MDBCol className="text-center col-md-6 col-12 ">
              <MDBBtn className="button-secondary py-0 px-3" outline color="dark" onClick={handleNewScan}>
                Volver a escanear
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <MDBModal staticBackdrop tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow className="text-center d-flex align-items-center py-4">
                <MDBCol className="text-center col-12 mb-3">
                  <p>

                    {
                      type === "Recompensa" && <>
                        Has asignado {challenge.challengeScore} cristales a:
                      </>
                    }
                    {
                      type === "Descuento" && <>
                        Has descontado {challenge.challengeScore} cristales a:
                      </>
                    }
                    {
                      type === "Venta" && <>
                        Has entregado {prize.prizeName} a cambio de {prize.prizeScore} cristales a:
                      </>
                    }
                  </p>
                  <h2>
                    {gamer.gamerName}
                  </h2>
                  {
                    type === "Recompensa" && <>
                      <p>
                        por completar el reto:
                      </p>
                      <h2>
                        "{challenge.challengeActivity} / {challenge.challengeName}"
                      </h2>
                    </>
                  }
                  {
                    type === "Descuento" && <>
                      <p>
                        por operación:
                      </p>
                      <h2>
                        "{challenge.challengeName}" en "Expogame Center"
                      </h2>
                    </>
                  }
                  {
                    type === "Venta" && <>
                      <p>
                        por canje:
                      </p>
                      <h2>
                        "{prize.prizeName}" en "Expogame Center"
                      </h2>
                    </>
                  }
                </MDBCol>
              </MDBRow>


            </MDBModalBody>
            <MDBModalFooter  >
              <MDBBtn className="button-primary py-0 px-3 mb-3"
                outline
                color="dark" onClick={
                  () => {
                    handleCreateUserScore()
                  }
                }>
                Finalizar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>

  );
};
export default ScoreAssignation;
