import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody, MDBInput,
    MDBModalFooter,
} from "mdb-react-ui-kit";
import braceletService from "../../services/bracelets";

const GamerData = () => {

    const token = JSON.parse(localStorage.getItem("token"));
    const gamer = JSON.parse(window.localStorage.getItem('gamer'));

    const navigate = useNavigate();
    return (
        <>
            <div className="page-cubes d-flex flex-column min-vh-100">
                <MenuComponent onProfile={false} />
                <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                    <MDBContainer>
                        <MDBRow className="d-flex align-items-center py-4">
                            <MDBCol className="text-center col-md-5 col-12 mx-auto mb-5">
                                <h1 className="fw-bold align-text-center mb-4">Datos del jugador</h1>
                                <MDBInput className="mb-3" disabled value={gamer.gamerName} label="Nombre de usuario" />
                                <MDBInput className="mb-3" disabled value={gamer.gamerDNI} label="Rut" />
                                <MDBInput className="mb-3" disabled value={gamer.gamerFullName} label="Nombre completo" />

                                <MDBBtn className="button-primary py-0 px-3 mt-5 mb-3 me-3 w-100"
                                    outline
                                    color="dark"
                                    onClick={() => { navigate('/qrscanner') }}
                                >
                                    Elegir operación
                                </MDBBtn>
                                <MDBBtn className="button-outline bg-white py-0 px-3 mb-3 w-100"
                                    outline
                                    color="dark"
                                    onClick={() => { navigate('/home') }}
                                >
                                    Volver al inicio
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </div>
            </div>
        </>
    )
}
export default GamerData