const get = async (id) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/notifications/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const getAll = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/notifications`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const create = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/notifications`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const update = async (id, formData) => {
    var requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/notifications/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const hideUserNotification = async (id) => {

    const formData = {
        'userNotificationStatus': 'oculta'
    }

    var requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/user_notifications/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const getAllGamerNotificationsByGamer = async (gamerId) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamer_notifications/gamer/${gamerId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const hideGamerNotification = async (id) => {

    const formData = {
        'gamerNotificationStatus': 'oculta'
    }

    var requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamer_notifications/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}


const getAllUserNotificationsByUser = async (userId) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/user_notifications/user/${userId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}


const remove = async (id) => {
    var requestOptions = {
        method: 'DELETE',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/notifications/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}


export default {
    get,
    getAll,
    create,
    update,
    remove,
    hideUserNotification,
    getAllGamerNotificationsByGamer,
    hideGamerNotification,
    getAllUserNotificationsByUser
}


