
const getPrizes = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + '/prizes', requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data

}
const getPrizeById = async (prize_id) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/prizes/${prize_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const createPrizeUserScore = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + '/prize_scores', requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

const getPrizeByVersion = async (version) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/prizes/version/${version}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => { return error })
    return data
}

export default {
    getPrizeById,
    getPrizes,
    createPrizeUserScore,
    getPrizeByVersion
}
