import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "./Login";
import {
  MDBContainer,
  MDBNavbar,
  MDBBtnm,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBSelect, MDBModal,
  MDBModalDialog, MDBModalTitle,
  MDBModalContent,
  MDBModalHeader,
  MDBScrollbar,
  MDBModalBody,
  MDBModalFooter,
  MDBSideNav,
  MDBSideNavLink,
  MDBSideNavItem
} from "mdb-react-ui-kit";
import { MDBInputMask } from 'mdb-react-input-mask';
import userService from "../../services/users"
import MenuComponent from "../MenuComponent";

const ProfileAdmin = () => {
  const navigate = useNavigate();
  const [basicOpen, setBasicOpen] = useState(false);
  const [admin, setAdmin] = useState({});

  const [centredModal, setCentredModal] = useState(false);
  const toggleShow = () => setCentredModal(!centredModal);


  const state = JSON.parse(window.localStorage.getItem('token'));
  if (!state) {
    {
      return <Login />;
    }
  }

  const [updatedAdmin, setUpdatedAdmin] = useState({
    userEmail: "",
    userDNI: "",
    userPhone: "",
    userPassword: "",
  });

  const handleAdminDataLoad = async () => {
    let userId = JSON.parse(atob(state.user)).id
    const user = await userService.getAdmin(userId);

    let userDNI = user.userDNI;
    //get last character
    let lastCharacter = userDNI.slice(-1);
    //get first 8 characters
    let firstEightCharacters = userDNI.slice(0, -1);
    //set firstEightCharacters as a number separated by dots (thousands)
    let firstEightCharactersDots = firstEightCharacters.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    //concatenate firstEightCharactersDots and lastCharacter
    let userDNIFormatted = firstEightCharactersDots + "-" + lastCharacter;
    user.userDNI = userDNIFormatted;

    user.userPassword = "";
    setAdmin(user);
  }

  useEffect(() => {
    handleAdminDataLoad();
  },
    [])

  useEffect(() => {
    setUpdatedAdmin({
      userEmail: admin.userEmail,
      userDNI: admin.userDNI,
      userPhone: admin.userPhone,
      userPassword: admin.userPassword,
    })
  }, [admin])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedAdmin((prevProps) => ({
      ...prevProps, [name]: value,
    }))
  }

  const updateAdmin = async () => {

    var requestOptions = {
      method: 'PATCH',
      body: JSON.stringify(updatedAdmin),
      redirect: 'manual'
    }
    await fetch(process.env.REACT_APP_API_BASEURL + "/users/" + admin.id, requestOptions)
      .then(response => response.text())
      .then(result => { toggleShow() })
      .catch(error => console.error(error));
  }

  return (
    <div className="page-cubes d-flex flex-column min-vh-100">
      <div className="banner-gradient">
        <MenuComponent onProfile={true} />
      </div>
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow>
            <MDBCol className="mx-auto col-md-6 text-center">

              <MDBInput
                className="mb-4"
                type="email"
                id="userDNI"
                name="userDNI"
                label="RUT"
                onChange={handleInputChange}
                value={updatedAdmin.userDNI || ''}
              />
              <MDBInput
                className="mb-4"
                type="email"
                id="userEmail"
                name="userEmail"
                label="Email"
                value={updatedAdmin.userEmail || ''}
                onChange={handleInputChange}
              />
              <MDBInput
                type="text"
                id="userPhone"
                name="userPhone"
                label="Teléfono"
                value={updatedAdmin.userPhone || ''}
                placeholder="+56 9 1234 5678"
                onChange={handleInputChange}
              />
              <MDBInput
                className="mb-4 mt-4"
                type="password"
                id="userPassword"
                name="userPassword"
                label="(Editar contraseña actual)"
                value={updatedAdmin.userPassword || ''}
                onChange={handleInputChange}
              />


              <div className="d-grid gap-2">
                <MDBBtn className="button-primary" outline color="dark" onClick={updateAdmin}>
                  Editar mis datos
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <MDBModal tabIndex='-1' show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Exito!</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p className="fw-bold mb-0">
                Datos actualizados
              </p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={() => {
                navigate('/profile')
              }}>
                Aceptar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};
export default ProfileAdmin;
