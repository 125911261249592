import React, { useEffect, useState } from "react";

function App() {
  const [user, setUser] = useState([]);

  const fetchData = () => {
    return fetch(process.env.REACT_APP_API_BASEURL + "/users")
      .then((response) => response.json())
      .then((data) => {
        const rows = data.map(item => ({

          id: item.id,
          userDNI: item.userDNI,
          role: item.role.roleName,
          userEmail: item.userEmail,

        }));
        setUser(rows)
      });
  }

  useEffect(() => {
    fetchData();
  }, [])

  return user;
}
export default App;