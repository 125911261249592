import { React } from "react";
import zoneService from "../services/zones";
import versionService from "../services/versions";


const App = async (user_id) =>{

    const zoneByUser = await zoneService.getZoneByUser(user_id);
    if (zoneByUser.length === 0) return false;
    const versionByUserId = zoneByUser[0].zone.version_id;
    const currentVersion = await versionService.getCurrentVersion();
    return (currentVersion.id === versionByUserId) 

}
export default App;