import React, { createRef, forwardRef, useRef } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuComponent from "../MenuComponent";
import IMG_QR from "../../img/imagen-qrcode.png";
import {
    MDBContainer, MDBRow, MDBCol, MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody, MDBInput,
    MDBModalFooter,
    MDBSelect
} from "mdb-react-ui-kit";
import braceletService from "../../services/bracelets";
import versionService from "../../services/versions";
import zoneService from "../../services/zones";
import challengeService from "../../services/challenges"
import prizeService from "../../services/prizes"

const QRScanner = () => {

    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(atob(token.user));
    const gamer = JSON.parse(window.localStorage.getItem('gamer'));
    const [zoneId, setZoneId] = useState(null)
    const [zone, setZone] = useState({
        id: zoneId,
        version_id: "",
        zoneName: "",
        zoneType: ""
    });
    const [zones, setZones] = useState([]);
    const [prizeZone, setPrizeZone] = useState(null);

    const [challengeId, setChallengeId] = useState('');
    const [challenge, setChallenge] = useState({
        id: challengeId,
        challengeDescription: "",
        challengeName: "",
        challengeScore: "",
        zone_id: zoneId,
        zone: ""
    });

    const [prizeId, setPrizeId] = useState('');
    const [prize, setPrize] = useState({
        id: prizeId,
        prizeName: "",
        prizeBrand: "",
        prizeScore: ""
    });

    const [formData, setFormData] = useState({});
    const [challengeList, setChallengeList] = useState(null);
    const [prizeList, setPrizeList] = useState(null);

    const [challengeModal, setChallengeModal] = useState(false);
    const [prizeModal, setPrizeModal] = useState(false);
    const [qrDelay, setQrDelay] = useState(500);
    const qrRef = createRef();

    const handleZoneDataLoad = async () => {
        const user_id = user.id;
        const newZoneByUser = await zoneService.getZoneByUser(user_id)

        if (newZoneByUser.length > 0) {
            setZones(
                newZoneByUser.map((zoneUser) => {
                    return {
                        id: zoneUser.zone.id,
                        zoneName: zoneUser.zone.zoneName,
                        zoneType: zoneUser.zone.zoneType,
                        version_id: zoneUser.zone.version_id
                    }
                })
            )
        }

    }

    const handleChallengeDataLoad = async () => {
        const newChallengeById = await challengeService.getChallengeById(challengeId);

        console.log(newChallengeById)

        let theZone = zones.find((zone) => {
            return newChallengeById.zone_id == zone.id
        })

        if (theZone) {
            const newChallenge = {
                id: newChallengeById.id,
                challengeDescription: newChallengeById.challengeDescription,
                challengeName: newChallengeById.challengeName,
                challengeScore: newChallengeById.challengeScore,
                challengeDifficulty: newChallengeById.challengeDifficulty,
                challengeActivity: newChallengeById.challengeActivity,
                zone_id: theZone.id,
                zone: theZone.zoneName,
            };
            setChallenge(newChallenge)
            setZoneId(theZone.id)
            const newZone = {
                id: theZone.id,
                version_id: theZone.version_id,
                zoneName: theZone.zoneName,
                zoneType: theZone.zoneType
            }
            setZone(newZone)
        } else {

        }
    }

    const handlePrizeDataLoad = async () => {

        const versionResult = await versionService.getCurrentVersion()
        const zoneResult = await zoneService.getZoneByVersionId(versionResult.id)

        const prizeZone = zoneResult.find((zone) => {
            return zone.zoneType == 'Premio'
        })

        const userZone = await zoneService.getZoneByUser(user.id)

        const userPrizeZone = userZone.find((userZone) => {
            return userZone.zone_id == prizeZone.id
        })

        if (userPrizeZone) {
            setPrizeZone(prizeZone)
            if (prizeId != '') {
                const newPrizeById = await prizeService.getPrizeById(prizeId);
                const newPrize = {
                    id: newPrizeById.id,
                    prizeName: newPrizeById.prizeName,
                    prizeBrand: newPrizeById.prizeBrand,
                    prizeScore: newPrizeById.prizeScore
                }
                setPrize(newPrize)
            }
        } else {
            setPrizeZone(null)
        }
    }

    const handleChallengeListDataLoad = async () => {
        var challenges = []
        challenges.push({
            id: '',
            challengeDescription: "",
            challengeName: "",
            challengeScore: "",
            challengeDifficulty: "",
            challengeActivity: "",
            zone_id: '',
            zone: "",
        })

        for (let i = 0; i < zones.length; i++) {
            const newChallengeByZone = await challengeService.getChallengeByZone(zones[i].id);
            challenges = challenges.concat(newChallengeByZone)
        }

        if (challenges.length > 0) {
            setChallengeList(challenges)
        } else {
            setChallengeList([])
        }

    }

    const handlePrizeListDataLoad = async () => {
        const versionResult = await versionService.getCurrentVersion()
        const zoneResult = await zoneService.getZoneByVersionId(versionResult.id)

        const prizeZone = zoneResult.find((zone) => {
            return zone.zoneType == 'Premio'
        })

        const userZone = await zoneService.getZoneByUser(user.id)

        const userPrizeZone = userZone.find((userZone) => {
            return userZone.zone_id == prizeZone.id
        })

        if (userPrizeZone) {
            let thePrizes = []

            const newPrizes = await prizeService.getPrizeByVersion(versionResult.id);

            thePrizes.push({
                id: '',
                prizeName: "",
                prizeBrand: "",
                prizeScore: ""
            })

            for (let i = 0; i < newPrizes.length; i++) {
                thePrizes.push(newPrizes[i])
            }

            if (thePrizes.length > 0){
                setPrizeList(thePrizes)
            }
            else
                setPrizeList([])
        } else {
            setPrizeList([])
        }
    }

    useEffect(() => {
        localStorage.removeItem('challenge')
        localStorage.removeItem('prize')
        if (zone.id == null) {
            handleZoneDataLoad();
        }
    }, [])

    useEffect(() => {
        handleChallengeListDataLoad();
    }, [zones])

    useEffect(() => {
        handlePrizeListDataLoad();
    }, [challengeList])

    useEffect(() => {
        if (challengeId != '') {
            handleChallengeDataLoad();
        }
    }, [challengeId])

    useEffect(() => {
        if (prizeId != '') {
            handlePrizeDataLoad();
        }
    }, [prizeId])

    useEffect(() => {
        if (challenge.id != '') {
            localStorage.setItem('challenge', JSON.stringify(challenge))
        }
    }, [challenge])

    useEffect(() => {
        if (prize.id != '') {
            localStorage.setItem('prize', JSON.stringify(prize))
        }
    }, [prize])

    useEffect(() => {
        if (formData?.type) {
            if (formData.type == 'challenge') {
                setChallengeId(formData.challenge_id)
            } else if (formData.type == 'prize') {
                setPrizeId(formData.prizeId)
            }
        }
    }, [formData])

    return (
        <>
            <div className="page-cubes d-flex flex-column text-center min-vh-100">
                <MenuComponent onProfile={false} />
                {
                    challengeList && prizeList && <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                        <MDBContainer className="w-100 text-center" >
                            <MDBRow className="text-center d-flex align-items-center py-4">
                                <MDBCol className="text-center col-md-5 col-12 mx-auto mb-5">
                                    <h1 className="fw-bold align-text-center mb-4">Operación</h1>

                                    <p className="text-center fs-5 mb-5">Elige la operación deseada.</p>

                                    {
                                        prizeList.length > 0 &&
                                        <MDBBtn className="button-primary py-0 px-3 mb-3 me-3 w-100"
                                            outline
                                            color="dark" onClick={
                                                () => {
                                                    setPrizeModal(true)
                                                }
                                            }> Elegir premio
                                        </MDBBtn>
                                    }

                                    {
                                        challengeList.length > 0 &&
                                        <MDBBtn className="button-primary py-0 px-3 mb-3 me-3 w-100"
                                            outline
                                            color="dark" onClick={
                                                () => {
                                                    setChallengeModal(true)
                                                }
                                            }> Elegir reto
                                        </MDBBtn>
                                    }

                                    <MDBBtn className="button-outline bg-white py-0 px-3 mb-3 w-100"
                                        outline
                                        color="dark"
                                        onClick={() => { navigate('/home') }}
                                    >
                                        Volver al inicio
                                    </MDBBtn>

                                </MDBCol>
                            </MDBRow>

                        </MDBContainer>
                    </div>
                }
            </div>

            {
                challengeList && <MDBModal staticBackdrop tabIndex='-1' show={challengeModal} setShow={setChallengeModal}>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Lista de retos</MDBModalTitle>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBSelect
                                    className="mb-3"
                                    label='Selecciona un reto'
                                    data={
                                        challengeList.map((challengeItem) => {
                                            let textData = ''
                                            if (challengeItem.challengeName !== '') {
                                                textData = challengeItem.challengeActivity + ' / ' + challengeItem.challengeName + ' / ' + challengeItem.challengeDescription + ' (' + challengeItem.challengeDifficulty + ')'
                                            }
                                            return {
                                                text: textData,
                                                value: challengeItem.id,
                                                description: challengeItem.challengeDescription,
                                                difficulty: challengeItem.challengeDifficulty,
                                                score: challengeItem.challengeScore,
                                                defaultSelected: challengeId == challengeItem.id
                                            }
                                        })
                                    }
                                    onValueChange={
                                        (item) => {
                                            if (item.value != '')
                                                setChallengeId(item.value)
                                        }
                                    }
                                    search
                                    searchLabel="Buscar"
                                />
                            </MDBModalBody>
                            <MDBModalFooter>
                                {
                                    challengeId != '' && <>
                                        <div className="my-4">
                                            <p>Desafío: {challenge.challengeActivity} / {challenge.challengeName}</p>
                                            <p>Dificultad: {challenge.challengeDifficulty}</p>
                                            <p>Puntaje: {challenge.challengeScore}</p>
                                        </div>
                                        <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                            outline
                                            color="dark" onClick={
                                                () => {
                                                    window.location.href = window.location.origin + "/qrscanner"
                                                }
                                            }> Reintentar
                                        </MDBBtn>
                                        <MDBBtn className="button-primary py-0 px-3 mb-3"
                                            outline
                                            color="dark" onClick={
                                                () => {
                                                    window.location.href = window.location.origin + "/scoreAssignation"
                                                }
                                            }> Continuar
                                        </MDBBtn>
                                    </>
                                }
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            }

            {
                prizeList && <MDBModal staticBackdrop tabIndex='-1' show={prizeModal} setShow={setPrizeModal}>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Lista de premios</MDBModalTitle>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBSelect
                                    className="mb-3"
                                    label='Selecciona un premio'
                                    data={
                                        prizeList.map((prizeItem) => {
                                            let textData = ''
                                            if (prizeItem.prizeName !== '') {
                                                textData = prizeItem.prizeBrand + ' / ' + prizeItem.prizeName + ' (' + prizeItem.prizeScore + ')'
                                            }
                                            return {
                                                text: textData,
                                                value: prizeItem.id,
                                                brand: prizeItem.prizeBrand,
                                                score: prizeItem.prizeScore,
                                                defaultSelected: prizeId == prizeItem.id
                                            }
                                        })
                                    }
                                    onValueChange={
                                        (item) => {
                                            if (item.value != '')
                                                setPrizeId(item.value)
                                        }
                                    }
                                    search
                                    searchLabel="Buscar"
                                />
                            </MDBModalBody>
                            <MDBModalFooter>
                                {
                                    prizeZone != null && prize.id != '' && <div className="my-4">
                                        <p>Premio: {prize.prizeName}</p>
                                        <p>Premio cuesta: {prize.prizeScore} cristales</p>
                                        <p>Jugador tiene: {gamer.gamerCurrentScore} cristales</p>
                                    </div>
                                }
                                {
                                    prizeZone != null ? (
                                        prize.id != '' &&
                                        <>

                                            {
                                                parseInt(gamer.gamerCurrentScore) >= parseInt(prize.prizeScore) ? (
                                                    <>
                                                        <MDBBtn className="button-primary py-0 px-3 mb-3"
                                                            outline
                                                            color="dark" onClick={
                                                                () => {
                                                                    window.location.href = window.location.origin + "/qrscanner"
                                                                }
                                                            }> Reintentar
                                                        </MDBBtn>
                                                        <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                                            outline
                                                            color="dark" onClick={
                                                                () => {
                                                                    window.location.href = window.location.origin + "/scoreAssignation"
                                                                }
                                                            }> Continuar
                                                        </MDBBtn>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="text-danger mb-4">
                                                            "{gamer.gamerName}" no tiene suficientes cristales para canjear este premio
                                                        </p>
                                                        <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                                            outline
                                                            color="dark"
                                                            onClick={() => {
                                                                window.location.href = window.location.origin + "/home"
                                                            }}
                                                        >
                                                            Volver al inicio
                                                        </MDBBtn>
                                                    </>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                                outline
                                                color="dark"
                                                onClick={() => {
                                                    window.location.href = window.location.origin + "/home"
                                                }}
                                            >
                                                Volver al inicio
                                            </MDBBtn>
                                        </>
                                    )

                                }
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            }

            {/*}
            <MDBModal staticBackdrop tabIndex='-1' show={qrReaderModal} setShow={setQrReaderModal}>
                <MDBModalDialog centered>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>ESCANEAR QR</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {
                                qrReaderModal &&
                                <>
                                    <div style={
                                        {
                                            display: !formData?.type ? 'block' : 'none',
                                        }
                                    }>
                                        <QrReader
                                            constraints={
                                                {
                                                    facingMode: "environment"
                                                }
                                            }
                                            onResult={
                                                (result) => {
                                                    if (result?.text) {
                                                        setFormData(JSON.parse(atob(result.text)))
                                                    }
                                                }
                                            }
                                            ref={qrRef}
                                        />
                                    </div>
                                </>
                            }
                            {
                                formData?.type == 'challenge' && challenge.id == '' && <div className="my-4">
                                    <p>Desafío no pertenece a tu zona</p>
                                </div>
                            }
                            {
                                formData?.type == 'challenge' && challenge.id != '' && <div className="my-4">
                                    <p>Desafío: {challenge.challengeActivity} / {challenge.challengeName}</p>
                                    <p>Dificultad: {challenge.challengeDifficulty}</p>
                                    <p>Puntaje: {challenge.challengeScore}</p>
                                </div>
                            }
                            {
                                formData?.type == 'prize' && prizeZone == null && <div className="my-4">
                                    <p>
                                        No tienes autorización para canjear premios
                                    </p>
                                </div>
                            }
                            {
                                formData?.type == 'prize' && prizeZone != null && prize.id != '' && <div className="my-4">
                                    <p>Premio: {prize.prizeName}</p>
                                    <p>Premio cuesta: {prize.prizeScore} cristales</p>
                                    <p>Jugador tiene: {gamer.gamerCurrentScore} cristales</p>
                                </div>
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            {
                                challenge.id != '' &&
                                <>
                                    <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                        outline
                                        color="dark" onClick={
                                            () => {
                                                window.location.href = window.location.origin + "/qrscanner"
                                            }
                                        }> Reintentar
                                    </MDBBtn>
                                    <MDBBtn className="button-primary py-0 px-3 mb-3"
                                        outline
                                        color="dark" onClick={
                                            () => {
                                                window.location.href = window.location.origin + "/scoreAssignation"
                                            }
                                        }> Continuar
                                    </MDBBtn>
                                </>
                            }
                            {
                                formData?.type == 'challenge' && challenge.id == '' &&
                                <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                    outline
                                    color="dark" onClick={
                                        () => {
                                            window.location.href = window.location.origin + "/qrscanner"
                                        }
                                    }> Reintentar
                                </MDBBtn>
                            }
                            {
                                formData?.type == 'prize' && prize.id == '' &&
                                <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                    outline
                                    color="dark" onClick={
                                        () => {
                                            window.location.href = window.location.origin + "/qrscanner"
                                        }
                                    }> Reintentar
                                </MDBBtn>
                            }
                            {
                                prizeZone != null ? (
                                    prize.id != '' &&
                                    <>

                                        {
                                            parseInt(gamer.gamerCurrentScore) >= parseInt(prize.prizeScore) ? (
                                                <>
                                                    <MDBBtn className="button-primary py-0 px-3 mb-3"
                                                        outline
                                                        color="dark" onClick={
                                                            () => {
                                                                window.location.href = window.location.origin + "/qrscanner"
                                                            }
                                                        }> Reintentar
                                                    </MDBBtn>
                                                    <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                                        outline
                                                        color="dark" onClick={
                                                            () => {
                                                                window.location.href = window.location.origin + "/scoreAssignation"
                                                            }
                                                        }> Continuar
                                                    </MDBBtn>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="text-danger mb-4">
                                                        "{gamer.gamerName}" no tiene suficientes cristales para canjear este premio
                                                    </p>
                                                    <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                                        outline
                                                        color="dark"
                                                        onClick={() => {
                                                            window.location.href = window.location.origin + "/home"
                                                        }}
                                                    >
                                                        Volver al inicio
                                                    </MDBBtn>
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        <MDBBtn className="button-secondary py-0 px-3 mb-3"
                                            outline
                                            color="dark"
                                            onClick={() => {
                                                window.location.href = window.location.origin + "/home"
                                            }}
                                        >
                                            Volver al inicio
                                        </MDBBtn>
                                    </>
                                )

                            }
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            {*/}
        </>
    )

}
//

export default QRScanner;