import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './components/views/Login';
import RecoverPassword from './components/views/RecoverPassword';
import NewPassword from './components/views/NewPassword';
import Home from './components/views/Home';
import UserList from './Controller/UserController';
import Confirmation from './components/views/Confirmation';
import BraceletScanner from './components/views/BraceletScanner';
import Profile from './components/views/Profile';
import GamerData from './components/views/GamerData';
import QRScanner from './components/views/QRScanner';
import ScoreAssignation from './components/views/scoreAsignation';
import Notifications from './components/views/Notifications';
function App() {

  return (

    <BrowserRouter basename='/'>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/recover-password' element={<RecoverPassword />} />
        <Route path='/new-password/:id/:token' element={<NewPassword />} />
        <Route path='/confirmation' element={<Confirmation />} />
        <Route path='/home' element={<BraceletScanner />} />
        <Route path='/users' element={<UserList />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/scanner' element={<BraceletScanner />} />
        <Route path='/gamerData' element={<GamerData />} />
        <Route path='/qrscanner' element={<QRScanner />} />
        <Route path='/scoreAssignation' element={<ScoreAssignation />} />
        <Route path='/notifications' element={<Notifications />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
