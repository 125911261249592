import React from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBBtn,
} from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom';
import imagenCube from '../../img/cubo_2023_expogame.png';



const Confirmation = () => {

    const navigate = useNavigate();

    const btnFinalizar = () => {
        navigate('/');
    };

    return (
        <div className="page-cubes d-flex flex-column min-vh-100">
            <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol className="col-md-6 mx-auto text-center">
                            <img src={imagenCube} alt="" className="mx-auto" />
                            <div className="my-4">
                                <h4 className="fw-bold mb-4 text-uppercase">¡Ya tienes registrado tu <br />Pasaporte Expogame!</h4>

                                <p className="h5">Te llegará un correo con el <br />enlace de acceso</p>
                            </div>
                            <MDBBtn className='button-primary' outline color='dark' onClick={btnFinalizar}>
                                Ir al Inicio
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
    );
}
export default Confirmation;