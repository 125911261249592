
const getChallenges = async () =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data= await fetch(process.env.REACT_APP_API_BASEURL + '/challenges', requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data

}
const getChallengeById = async (challenge_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data= await fetch(process.env.REACT_APP_API_BASEURL + `/challenges/${challenge_id}`, requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data
}

const getChallengeByZone = async (zone_id) =>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/challenges/zone/${zone_id}`, requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
 
    return data
}
const createChallengeUserScore = async (formData) =>{
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    const data= await fetch(process.env.REACT_APP_API_BASEURL + '/challenge_scores', requestOptions)
    .then( response => response.json())
    .then ( result => {
        return result
    })
    .catch( error => {return error})
    return data
}
export default {
    getChallengeByZone,
    getChallengeById,
    getChallenges,
    createChallengeUserScore
}
