import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
} from "mdb-react-ui-kit";
import imagenText from '../../img/textos/recuperarcuenta.png';
import userService from "../../services/users";

const ForgotPassword = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = React.useState(
    {
      gamerSignIn: '',
    }
  );

  const [errorModal, setErrorModal] = useState(false);
  const [varyingMessage, setVaryingMessage] = useState('');
  const [varyingTitle, setVaryingTitle] = useState(''); // 'Error', 'Éxito'
  const [goToLogin, setGoToLogin] = useState(false);

  const btnFinalizar = async () => {
    //navigate('/new-password');

    const response = await userService.sendGamerPasswordRecoveryRequest(formData)

    if (response.status === 400) {
      setVaryingMessage(response.messages.error),
        setVaryingTitle('Error'),
        setErrorModal(!errorModal)
    } else {
      setVaryingMessage('Se ha enviado un correo a tu cuenta de correo electrónico.'),
        setErrorModal(!errorModal),
        setVaryingTitle('Éxito'),
        setGoToLogin(true);
    }

  };

  useEffect(() => {
    if (goToLogin && !errorModal) {
      navigate('/login');
    }
  }, [goToLogin, errorModal])

  return (
    <div className="page-cubes d-flex flex-column min-vh-100">
      <div className="banner-gradient">
        <MDBContainer fluid className="banner-bg py-lg-4 py-3 text-center">
          <img src={imagenText} alt="" className="mx-auto" />
        </MDBContainer>
      </div>
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <MDBContainer>
          <MDBRow>
            <MDBCol className="col-md-6 col-12 mx-auto">
              <p className="mb-4">
                Si olvidaste tu contraseña realiza los siguientes pasos:
              </p>
              <p className="fw-bold">Ingresa tus datos</p>
              <form action="" className="mb-4">
                <MDBInput
                  type="email"
                  id="emailInput"
                  className="mb-4"
                  label="Nombre de usuario o email"
                  onChange={(e) => setFormData({ ...formData, gamerSignIn: e.target.value })}
                  value={formData.gamerSignIn}
                />
                <div className="text-center">
                  <MDBBtn type="button" className='button-primary' outline color='dark' onClick={btnFinalizar}>Enviar</MDBBtn>
                </div>
              </form>
            </MDBCol>
          </MDBRow>

          <MDBModal show={errorModal} setShow={setErrorModal} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {errorModal && varyingTitle}
                  </MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={() => setErrorModal(!errorModal)}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <div className='mb-3'>
                    {errorModal && varyingMessage}
                  </div>
                </MDBModalBody>
                <MDBModalFooter>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </MDBContainer>
      </div>
    </div>
  )
};

export default ForgotPassword;
