const getGamers = async () => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamers`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return data
}


const getAdmins = async (role_id) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/users/role/${role_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return data
}

const updateAdmin = async (admin_id, formData) => {

    var requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/users/${admin_id}`, requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

//ACTUALIZACION GAMER
const updateGamer = async (gamerID, formData) => {

    var requestOptions = {
        method: 'PATCH',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamers/${gamerID}`, requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}
const createGamer = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers", requestOptions)
        .then(response => response.text())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const registerGamer = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/register", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const deleteUser = async (admin_id) => {
    var raw = "";
    var requestOptions = {
        method: "DELETE",
        body: raw,
        redirect: "follow",
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/users/${admin_id}`,
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            return result
        })
        .catch((error) => console.log("error", error));

    return data

}
const createAdmin = async (formData) => {

    var requestOptions = {
        method: "POST",
        body: JSON.stringify(formData),
        redirect: 'follow'
    }
    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/users", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data;
}

const getUser = async (user_id) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/gamers/${user_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return data
}

const getAdmin = async (admin_id) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const data = await fetch(process.env.REACT_APP_API_BASEURL + `/users/${admin_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return data
}

const checkRegister = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/check-register", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const sendGamerPasswordRecoveryRequest = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/request-password-recovery", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const checkGamerPasswordRecoveryRequest = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/check-password-recovery-request", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const updateGamerPassword = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/set-new-password", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const notifyGamerRegistration = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/notify-gamer-registration", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const adminSignIn = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/users/sign-in", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

const gamerSignIn = async (formData) => {
    var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formData),
        redirect: 'follow'
    };

    const data = await fetch(process.env.REACT_APP_API_BASEURL + "/gamers/sign-in", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => console.log('error', error));
    return data
}

export default {
    getGamers,
    getUser,
    getAdmins,
    getAdmin,
    updateAdmin,
    deleteUser,
    updateGamer,
    createGamer,
    registerGamer,
    checkRegister,
    sendGamerPasswordRecoveryRequest,
    checkGamerPasswordRecoveryRequest,
    updateGamerPassword,
    notifyGamerRegistration,
    adminSignIn,
    createAdmin,
    gamerSignIn
};